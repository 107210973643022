.general-container {
    /* max-width: 100% !important; */
    margin-top: 15px;
}

.col-inline {
    display: inline-flex;
}

.page-title .btn {
    margin-left: 40px;
    align-self: center;
}

.col-params {
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
}

.col-params > * {
    margin-right: 20px;
}

.col-params:last-child {
    margin-right: 0;
}

.select-custom {
    min-width: 300px;
}



/*----- Header start-----*/
.header-link {
    color: rgba(255,255,255,.5);
    margin-right: 20px;
}

.header-link:last-child {
    margin-right: 0;
}

.header-link:focus,
.header-link:hover {
    color: rgba(255,255,255,.75);
}

.header-link.active {
    color: rgba(255,255,255,.75);
}
/*----- Header end-----*/



/*----- SignIn start-----*/
.auth-container {
    margin-top: 30px;
}

.auth-form {
    margin-top: 30px;
}
/*----- SignIn end-----*/



/*----- TaskSingle start-----*/
.task-edit__form {
    width: 100%;
    max-width: 600px;
}

.task-state__container {
    margin-bottom: 10px;
}

.task-created {
    margin-left: 15px;
}
/*----- TaskSingle end-----*/



/*----- TaskSingleView start-----*/
.line {
    width: 100%;
    background-color: #ccc;
    height: 1px;
    margin-top: 10px;
    margin-bottom: 10px;
}
/*----- TaskSingleView end-----*/



/*----- TaskTable start-----*/
.task-table tbody td {
    cursor: pointer;
}

.task-table__contractor {
    font-size: 13px;
    margin-bottom: 5px;
}

.text-editor__body img {
    max-width: 100%;
    max-height: 100%;
}

.tasks-table__buttons{
    width: 160px;
}

.tasks-table__buttons button{
    margin: 3px;
}

#more-actions {
    background-image: url(../images/more-btn.png);
    background-size: 16px;
    background-position: center;
    background-repeat: no-repeat;
    padding-left: 20px;
}

#more-actions::after {
    border: 0;
}

.td-status {
    width: 180px;
}

.td__project {
    font-size: 14px;
}

.hidden {
    display: none;
}

.td-hidden-show td,
.td-hidden-show:hover td {
    background-color: #fff !important;
    cursor: default;
}
/*----- TaskTable end-----*/



/*----- TaskEditor start-----*/
.text-editor__wrapper {
    border: 1px solid #ced4da;
}

.text-editor__body {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
}
/*----- TaskEditor end-----*/



/*----- DateTimePicker start-----*/
.datetime-picker {
    border: 1px solid #ced4da;
    border-radius: .25rem;
    /* height: 38px; */

    height: calc(2.25rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
}

.datetime-picker .react-datetime-picker__wrapper{
    border: 0;
}
/*----- DateTimePicker end-----*/



/*----- CheckList start-----*/
.checklist__percent {
    font-size: 12px;
    margin-top: 3px;
    margin-bottom: 5px;
}

.checklist__listitem {
    display: flex;
    justify-items: center;
    margin-top: 7px;
    margin-bottom: 7px;
    position: relative;
    padding: 5px;
}

.checklist__listitem-del {
    position: absolute;
    right: 0;
    display: none;
}

.checklist__listitem:hover {
    background-color: rgba(9,45,66,.08);
}

.checklist__listitem:hover .checklist__listitem-del {
    display: block;
}

.checklist__empty-textarea {
    background-color: rgba(9,45,66,.08);
    border-radius: 6px;
    padding: 10px 15px;
    font-size: 14;
    height: 45px;
    cursor: pointer;
    color: #6b808c;
}

.checklist__empty-textarea:hover {
    background-color: rgba(9,45,66,.13);
}
/*----- CheckList end-----*/



/*----- TasksPage start-----*/
.tasks__count {
    font-size: 15px;
    color: #555;
    font-style: italic;
}

.tasks__only_my {
    display: flex;
    align-items: center;
    padding-left: 30px;
}

.task-filter {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.task-filter__fields {
    width: 100%;
    margin: 0;
    align-items: center;
}

.task-filter__col {
    padding-left: 7px;
    padding-right: 7px;
}

.task-filter__col:first-child {
    padding-left: 0;
}

.task-filter__fields .select-custom {
    min-width: auto;
}

.card-body img {
    max-width: 100%;
    max-height: 100%;
}
/*----- TasksPage end-----*/